import {memo} from "react"
import {BrowserRouter, Routes, Route} from "react-router-dom"
import {ProtectedRoute} from "./components/ProtectedRoute"
import {TopPage} from "./pages/TopPage"
import {MembersPage} from "./pages/MembersPage"
import {MembersWebcamPage} from "./pages/MembersWebcamPage"
import {PosPage} from "./pages/PosPage"
import {PosWebcamPage} from "./pages/PosWebcamPage"
import {MaintenancePage} from "./pages/MaintenancePage"

export const App = memo(() => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<ProtectedRoute component={TopPage}/>}/>
        <Route path={"members"} element={<ProtectedRoute component={MembersPage}/>}/>
        <Route path={"members-webcam"} element={<ProtectedRoute component={MembersWebcamPage}/>}/>
        <Route path={"pos"} element={<ProtectedRoute component={PosPage}/>}/>
        <Route path={"pos-webcam"} element={<ProtectedRoute component={PosWebcamPage}/>}/>
        <Route path={"maintenance"} element={<ProtectedRoute component={MaintenancePage}/>}/>
      </Routes>
    </BrowserRouter>
  )
})
