import {useCallback, useState, useContext} from "react"
import {useDropzone} from "react-dropzone"
import {PersonRegistrationContext} from "../components/providers/PersonRegistrationProvider"

export const useDragAndDrop = () => {
  const {setImageFile} = useContext(PersonRegistrationContext)
  const [isDropped, setIsDropped] = useState<boolean>(false)

  const onDrop = useCallback((acceptedFiles: any) => {
    console.log('acceptedFiles:', acceptedFiles)
    if (acceptedFiles.length !== 0) {
      setIsDropped(true)
      setImageFile(acceptedFiles[0])
    }
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    {
      getRootProps,
      getInputProps,
      isDragActive,
      isDropped
    }
  )
}
