// WebAPI 3.2 顔検出
import axios from "axios"
import {PersonRegistrationContext} from "../components/providers/PersonRegistrationProvider"
import {configuration} from "../configuration"
import {useContext, useEffect, useRef, useState} from "react"
import Webcam from "react-webcam"

export const usePersonIdentify = () => {
  const {imageFile} = useContext(PersonRegistrationContext)
  const [isPostError, setIsPostError] = useState<boolean>(false)
  const [isRegistered, setIsRegistered] = useState(false)
  const [message, setMessage] = useState<string>("")
  const [identificationImage, setIdentificationImage] = useState<Blob | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [confidenceThreshold, setConfidenceThreshold] = useState<number>(60)

  const onThresholdChange = (event: any, func: Function) => {
    setConfidenceThreshold(event.target.value)
    func()
  }

  // const clearWebcamData = () => {
  //   setUrl(null)
  //   setIdentificationImage(null)
  //   setMessage("")
  // }

  const clearHistory = () => {
    setIdentificationImage(null)
    setMessage("")
  }

  const onClickNotOfAge = () => {
    setIsPostError(true)
    setMessage("未成年者ゆえアテンダントコール発生")
  }

  const postPersonIdentify = () => {
    personIdentify(imageFile)
  }

  const personIdentify = async (imageFile: any) => {
    setIsLoading(true)
    console.log("閾値：", confidenceThreshold / 100)

    // フォームデータ
    const formData = new FormData()
    formData.append('file', imageFile)
    formData.append('data', `{"confidence_threshold": ${confidenceThreshold / 100}}`)

    // POST（顔認証）
    try {
      const response = await axios({
        method: 'post',
        url: `${configuration.baseUrl}/identify`,
        headers: {
          'x-api-key': configuration.apiKey,
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      })
      console.log(response)
      setIsPostError(false)
      if (response.data.person_id === undefined) {
        // 該当人物なし
        console.log("会員登録されていません： ", response.data.identification_id)
        setMessage("会員登録されていないのでアテンダントコールが必要")
        setIdentificationImage(new Blob([], {}))
        setIsRegistered(false)
      } else {
        // 該当人物あり
        console.log(`顔認証に成功しました： person_id=${response.data.person_id}, identification_id=${response.data.identification_id}`)
        // setMessage(`顔認証に成功しました： ${response.data.person_id} : ${response.data.identification_id}`)
        setMessage("登録済のこの人物と一致したのでアテンダントコールは不要")
        try {
          // 該当人物の登録済画像を取得する
          console.log("該当人物の画像を取得します")
          const responseGetImage = await axios({
            method: 'get',
            // url: `${configuration.baseUrl}/image/identify/${response.data.identification_id}`,
            url: `${configuration.baseUrl}/image/${response.data.person_id}`,
            headers: {'x-api-key': configuration.apiKey, 'Content-Type': 'image/jpeg'},
            responseType: 'arraybuffer'
          })
          console.log("該当人物の画像取得に成功しました。", responseGetImage)
          console.log("Blog: ", new Blob([responseGetImage.data], {type: 'image/jpeg'}))
          // setIdentificationImage(responseGetImage.data.blob())
          setIdentificationImage(new Blob([responseGetImage.data], {type: 'image/jpeg'}))
          setIsRegistered(true)
        } catch (error: any) {
          console.log("該当人物の画像取得に失敗しました。", error)
        }
      }
    } catch (error: any) {
      console.log(error)
      console.log(`顔認証リクエスト時にエラー発生：`, error)
      setIsPostError(true)
      console.log("顔認証に失敗しました： ", error.response.data.message)
      setMessage(`顔認証に失敗しました： ${error.response.data.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    {
      postPersonIdentify,
      personIdentify,
      isPostError,
      message,
      identificationImage,
      isLoading,
      isRegistered,
      onThresholdChange,
      onClickNotOfAge,
      confidenceThreshold,
      clearHistory,
    }
  )
}
