import {Header} from "../components/Header"
import {Title} from "../components/Title"
import {Loading} from "../components/Loading"
import {MemberControlButton} from "../components/MemberControlButton"
import {MemberList} from "../components/MemberList"
import {usePersonsList} from "../hooks/usePersonsList"

export const MaintenancePage = () => {
  const {personsList, onClickBack, onClickNext, onClickDeleteButton, isLoading, page, isLastPage} = usePersonsList()

  return (
    <div>
      <Header/>
      <Title/>
      <div className={"maintenance"}>
        <h2>登録済会員のメンテナンスページ</h2>
        <p>登録済会員一覧</p>
        <MemberControlButton onClickBack={onClickBack} onClickNext={onClickNext} page={page} isLastPage={isLastPage}/>
        {
          isLoading ? (
            <Loading/>
          ) : (
            <MemberList personsList={personsList} onClickDeleteButton={onClickDeleteButton}/>
          )
        }
      </div>
    </div>
  )
}
