import type {MemberListPropsType} from "../types/AppTypes"

import "../App.css"

export const MemberList = (props: MemberListPropsType) => {
  const {personsList, onClickDeleteButton} = props

  return (
    <div className={"personsList"}>
      <ul>
        {
          personsList.map((person, index) => {
            return (
              <li key={person.personId}>
                <span>
                  {person.name}
                  <img className={"faceImage"} src={URL.createObjectURL(person.image)} alt={"face"}/>
                  <button onClick={() => onClickDeleteButton({"person_id": person.personId, "index": index})} className={"button"} >削除</button>
                </span>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}
