import {memo, useContext} from "react"
import {PersonRegistrationContext} from "../components/providers/PersonRegistrationProvider"

import {useDragAndDrop} from "../hooks/useDragAndDrop"
import {usePersonRegistration} from "../hooks/usePersonRegistration"

import {Header} from "../components/Header"
import {Title} from "../components/Title"
import {InputName} from "../components/InputName"
import {InputImage} from "../components/InputImage"
import {Loading} from "../components/Loading"

import "../App.css"


export const MembersPage = memo(() => {
  const {imageFile} = useContext(PersonRegistrationContext)
  const {getRootProps, getInputProps, isDragActive, isDropped} = useDragAndDrop()
  const {onChangeName, postPersonRegistration, isName, isPostError, message, isLoading} = usePersonRegistration()

  return (
    <div>
      <Header/>
      <Title/>
      <div className={"members"}>
        <h2>会員登録ページ</h2>
        <InputName onChangeName={onChangeName}/>
        <InputImage getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive}
                    isDropped={isDropped} imageFile={imageFile}/>
        <button className={"button"} onClick={postPersonRegistration} disabled={!isName || !isDropped}>会員を登録する</button>
        {
          isLoading ? (
            <Loading/>
          ) : (
            isPostError ? (<p style={{color: "red"}}>{message}</p>) : (<p style={{color: "green"}}>{message}</p>)
          )
        }
        {/*{*/}
        {/*  isPostError ? (<p style={{color: "red"}}>{message}</p>) : (<p style={{color: "green"}}>{message}</p>)*/}
        {/*}*/}
      </div>
    </div>
  )
})
