// WebAPI 3.3 - 人物登録 のコール

import {useState, useContext} from "react"
import axios from "axios"
import {PersonRegistrationContext} from "../components/providers/PersonRegistrationProvider"
import {configuration} from "../configuration"

export const usePersonRegistration = () => {
  const {name, setName, imageFile} = useContext(PersonRegistrationContext)
  const [isName, setIsName] = useState<boolean>(false)
  const [isPostError, setIsPostError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const postPersonRegistration = () => {
    if (name.length > 0 && imageFile != null) {
      personRegistration(imageFile)
    }
  }

  const personRegistration = async (imageFile: any) => {
    setIsLoading(true)
    setIsName(false)
    setName("")
    // フォームデータ
    const formData = new FormData()
    formData.append('file', imageFile)
    formData.append('data', `{ "user_data": "${name}", "image_preserve": true }`)

    // POST（人物登録）
    try {
      const response = await axios({
        method: 'post',
        url: `${configuration.baseUrl}/persons`,
        headers: {
          'x-api-key': configuration.apiKey,
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      })
      setIsPostError(false)
      console.log("会員登録に成功しました： ", response.data.person_id, response.data.user_data)
      setMessage(`会員登録に成功しました： ${response.data.person_id} : ${response.data.user_data}`)
    } catch (error: any) {
      setIsPostError(true)
      console.log("会員登録に失敗しました： ", error.response.data.message)
      setMessage(`会員登録に失敗しました： ${error.response.data.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeName = (name: string) => {
    if (name.length <= 0) {
      setName("")
      setIsName(false)
    } else {
      setName(name)
      setIsName(true)
    }
  }

  return (
    {
      onChangeName,
      postPersonRegistration,
      personRegistration,
      isName,
      isPostError,
      message,
      isLoading,
    }
  )
}
