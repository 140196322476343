import {memo} from "react"

import {InputImagePropsType} from "../types/AppTypes"

export const InputImage = memo( (props: InputImagePropsType) => {
  const {getRootProps, getInputProps, isDragActive, isDropped, imageFile} = props
  return (
    <div className={"imageContainer"}>
      <div className="dndArea">
        <div {...getRootProps()} className={"dropzone"}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              (
                <p>画像ファイルをここにドロップしてください...</p>
              ) : (
                <p>画像ファイルをドラッグ&ドロップ。または枠内をクリック後、画像ファイルを選択してください。</p>
              )
          }
        </div>
      </div>
      <div>
        {
          isDropped && imageFile ?
            (
              <div>
                <img className={"faceImage"} src={URL.createObjectURL(imageFile)} alt={"face"}/>
              </div>
            ) : (
              <p>画像なし</p>
            )
        }
      </div>
    </div>
  )
})
