import {useEffect, useRef, useState} from "react";
import Webcam from "react-webcam";

export const useWebcam = () => {
  const [deviceId, setDeviceId] = useState<string>("")
  const [url, setUrl] = useState<string | null>(null)
  const webcamRef = useRef<Webcam>(null)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const videoConstraints = {
    width: 200,
    height: 250,
    facingMode: "user",
    deviceId
  }

  const clearWebcamData = () => {
    setUrl(null)
  }

  const onChangeWebcam = (event: any) => {
    setDeviceId(event.target.value)
    clearWebcamData()
  }

  const onClickRestart = (func: Function | null) => {
    clearWebcamData()
    if ( func !== null ) func()
  }

  const onClickCapture = (func: Function) => {
    const imageSrc = webcamRef.current?.getScreenshot()
    //console.log("capture", imageSrc)
    if (imageSrc) {
      const blob = atob(imageSrc.replace(/^.*,/, ''))
      let buffer = new Uint8Array(blob.length)
      for (let i = 0; i < blob.length; i++) {
        buffer[i] = blob.charCodeAt(i)
      }
      // const file = new File([buffer.buffer], imageSrc, {type: 'image/jpeg'})
      const file = new File([buffer.buffer], "image.jpeg", {type: 'image/jpeg'})
      setUrl(imageSrc)
      // personRegistration(file)
      func(file)
    }
  }

  useEffect(() => {
    // 利用可能なWebカメラの一覧を得る
    navigator.mediaDevices.enumerateDevices()
      .then((mediaDevices) => {
        const newDevices = mediaDevices.filter(({kind}: MediaDeviceInfo) => kind === "videoinput")
        setDevices(newDevices)
        setDeviceId(newDevices[0].deviceId)
        console.log(newDevices)
      })
  }, [])

  return (
    {
      videoConstraints,
      devices, deviceId, onChangeWebcam,
      onClickCapture, url, webcamRef,
      onClickRestart,
      clearWebcamData,
    }
  )
}
