import Webcam from "react-webcam"

import {usePersonIdentify} from "../hooks/usePersonIdentify"
import {useWebcam} from "../hooks/useWebcam"

import {Header} from "../components/Header"
import {Title} from "../components/Title"
import {Loading} from "../components/Loading"
import {SelectThreshold} from "../components/SelectThreshold"
import {SelectWebcam} from "../components/SelectWebcam"
import {FaceImage} from "../components/FaceImage"


export const PosWebcamPage = () => {
  const {
    onClickNotOfAge,
    onThresholdChange, confidenceThreshold,
    isLoading, isPostError, isRegistered,
    identificationImage,
    message,
    personIdentify,
    clearHistory,
  } = usePersonIdentify()

  const {
    videoConstraints,
    devices, deviceId, onChangeWebcam,
    onClickCapture, url, webcamRef,
    onClickRestart,
    clearWebcamData
  } = useWebcam()

  return (
    <div>
      <Header/>
      <Title/>
      <div className={"posWebCam"}>
        <SelectWebcam onChangeWebcam={onChangeWebcam} deviceId={deviceId} devices={devices}/>
        <SelectThreshold onThresholdChange={(event) => onThresholdChange(event, clearWebcamData)} confidenceThreshold={confidenceThreshold}/>
        {
          url ? (
            <img src={url} alt={"image"} />
          ) : (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          )
        }

        <div className={"selfPos"}>
          <p>年齢確認商品です</p>
          <p>２０歳以上ですか？</p>
          <button className={"button"} onClick={() => onClickCapture(personIdentify)}>はい</button>
          <button className={"button"} onClick={onClickNotOfAge}>いいえ</button>
        </div>
        <div className={"result"}>
          <button onClick={() => onClickRestart(clearHistory)}>もう一度最初から</button>
          {
            isLoading ? (
              <Loading/>
            ) : (
              isPostError ? (
                <div>
                  <span style={{color: "red"}}>{message}</span>
                </div>
              ) : (
                <div>
                  <FaceImage identificationImage={identificationImage} isRegistered={isRegistered} message={message}/>
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  )
}
