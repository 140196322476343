import {useContext} from "react"
import {PersonRegistrationContext} from "./providers/PersonRegistrationProvider"

import {InputNamePropsType} from "../types/AppTypes"

export const InputName = ({onChangeName}: InputNamePropsType) => {
  const {name} = useContext(PersonRegistrationContext)

  return (
    <div className={"inputName"}>
      名前：
      <input type={"text"} value={name} placeholder={"名前を入力してください"} style={{width: "170px"}}  onChange={event => {
        onChangeName(event.target.value)
      }}/>
    </div>
  )
}
