import {MemberControlButtonPropsType} from "../types/AppTypes"

export const MemberControlButton = (props: MemberControlButtonPropsType) => {
  const {onClickBack, onClickNext, page, isLastPage} = props
  return (
    <div>
      {
        page <= 1 ? (
          <button className={"button"} onClick={onClickBack} disabled={true}>戻る</button>
        ) : (
          <button className={"button"} onClick={onClickBack}>戻る</button>
        )
      }
      {
        isLastPage ? (
          <button className={"button"} onClick={onClickNext} disabled={true}>次へ</button>
        ) : (
          <button className={"button"} onClick={onClickNext}>次へ</button>
        )
      }
    </div>
  )
}
