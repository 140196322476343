import {memo, useContext} from "react"

import {Header} from "../components/Header"
import {Title} from "../components/Title"
import {InputImage} from "../components/InputImage"
import {Loading} from "../components/Loading"
import {FaceImage} from "../components/FaceImage"
import {SelectThreshold} from "../components/SelectThreshold"

import {PersonRegistrationContext} from "../components/providers/PersonRegistrationProvider"
import {useDragAndDrop} from "../hooks/useDragAndDrop"
import {usePersonIdentify} from "../hooks/usePersonIdentify"

export const PosPage = memo(() => {
  const {imageFile} = useContext(PersonRegistrationContext)
  const {getRootProps, getInputProps, isDragActive, isDropped} = useDragAndDrop()
  const {
    onThresholdChange,
    confidenceThreshold,
    postPersonIdentify,
    isPostError,
    message,
    identificationImage,
    isLoading,
    isRegistered
  } = usePersonIdentify()

  return (
    <div>
      <Header/>
      <Title/>
      <div className={"pos"}>
        <h2>セルフ精算機での支払</h2>
        <div>
          <SelectThreshold onThresholdChange={(event) => onThresholdChange(event, ()=>{})} confidenceThreshold={confidenceThreshold}/>
        </div>
        <InputImage getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive}
                    isDropped={isDropped} imageFile={imageFile}/>
        {
          isDropped ?
            (
              <div>
                <p>あなたは20歳以上ですか？</p>
                <button className={"button"} onClick={postPersonIdentify}>はい</button>
                <button className={"button"}>いいえ</button>
              </div>
            ) : (
              <div></div>
            )
        }
        {
          isLoading ? (
            <Loading/>
          ) : (
            isPostError ? (
              <div>
                <span style={{color: "red"}}>{message}</span>
              </div>
            ) : (
              isDropped ? (
                <FaceImage identificationImage={identificationImage} isRegistered={isRegistered} message={message}/>
              ) : (
                <div></div>
              )
            )
          )
        }
      </div>
    </div>
  )
})
