import React, {createContext, useState} from "react"

export interface PersonRegistrationContextType {
  name: string
  setName:  React.Dispatch<React.SetStateAction<string>>
  isName: boolean
  setIsName: React.Dispatch<React.SetStateAction<boolean>>
  imageFile: File | null
  setImageFile: React.Dispatch<React.SetStateAction<File | null>>
}

export const PersonRegistrationContext = createContext( {} as PersonRegistrationContextType )

export const PersonRegistrationProvider = (props: any) => {
  const {children} = props
  const [name, setName] = useState<string>("")
  const [isName, setIsName] = useState<boolean>(false)
  const [imageFile, setImageFile] = useState<File | null>(null)

  const valueObject: PersonRegistrationContextType = {
    name,
    setName,
    isName,
    setIsName,
    imageFile,
    setImageFile
  }

  return (
    <PersonRegistrationContext.Provider value={valueObject}>
      {children}
    </PersonRegistrationContext.Provider>
  )
}
