import {memo} from "react"
import {NavLink} from "react-router-dom";
import {LogoutButton} from "./LogoutButton"
import "../App.css"

const brandmark = require("../images/ci-brandmark.jpg")

export const Header = memo( () => {
  return (
    <div className={"headerContainer"}>
      <NavLink to={"/"}>
        <img src={brandmark} style={{height: "50px"}} alt={"logo"}/>
      </NavLink>
      <LogoutButton/>
    </div>
  )
})
