import type {SelectWebcamPropsType} from "../types/AppTypes"

export const SelectWebcam = (props: SelectWebcamPropsType) => {
  const {onChangeWebcam, deviceId, devices} = props
  return (
    <div>
      Webカメラは
      <select onChange={onChangeWebcam} value={deviceId}>
        {
          devices.map((device, key) => {
            let label = ""
            device.label === "" ? ( label = `カメラデバイス_${key + 1}` ) : (label = device.label)
            return (<option value={device.deviceId} key={key}>{label}</option>)
        })
        }
      </select>
      を使います。
    </div>
  )
}
