import type {FaceImagePropsType} from "../types/AppTypes"

export const FaceImage = (props: FaceImagePropsType) => {
  const {identificationImage, isRegistered, message} = props

  return (
    <div>
      {
        isRegistered && identificationImage ? (
          <div className={"faceImage"}>
            <hr/>
            {message}
            {/*[お買い上げありがとうございました。]<br/>*/}
            {/*登録済のこの人物と一致したのでアテンダントコールは不要。*/}
            <img className={"identificationImage"} src={URL.createObjectURL(identificationImage)} alt={"face"}/>
          </div>
        ) : (
          <div className={"faceImage"}>
            <hr/>
            {message}
            {/*[店員を呼び出しています]<br/>*/}
            {/*会員登録されていない（一致する顔がない）のでアテンダントコール発生。*/}
          </div>
        )
      }
    </div>
  )
}
