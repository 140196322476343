import {useContext} from "react"
import {PersonRegistrationContext} from "../components/providers/PersonRegistrationProvider"

import {usePersonRegistration} from "../hooks/usePersonRegistration"
import {useWebcam} from "../hooks/useWebcam"

import {Header} from "../components/Header"
import {Title} from "../components/Title"
import {InputName} from "../components/InputName"
import {Loading} from "../components/Loading"
import {SelectWebcam} from "../components/SelectWebcam"

import "../App.css"
import Webcam from "react-webcam";

export const MembersWebcamPage = () => {
  const {setImageFile} = useContext(PersonRegistrationContext)
  const {
    onChangeName,
    isName, isPostError, isLoading,
    personRegistration,
  } = usePersonRegistration()
  const {
    videoConstraints,
    devices, deviceId, onChangeWebcam,
    onClickCapture, url, webcamRef,
    onClickRestart
  } = useWebcam()

  return (
    <div>
      <Header/>
      <Title/>
      <div className={"membersWebcam"}>
        <h2>会員登録ページ（Webcam）</h2>
        <SelectWebcam onChangeWebcam={onChangeWebcam} deviceId={deviceId} devices={devices}/>
        <InputName onChangeName={onChangeName}/>
        {
          url ? (
                isLoading ? (
                  <Loading/>
                ) : (
                  <div style={{display:"inherit", flexDirection: "inherit"}}>
                    <img src={url} alt={"image"}/>
                    {
                      isPostError ? (
                        <p>登録に失敗しました</p>
                      ) : (
                        <p>登録しました</p>
                      )
                    }
                    <button onClick={() => onClickRestart(setImageFile)}>次の人を登録する</button>
                  </div>
                )
          ) : (
            <div style={{display:"inherit", flexDirection: "inherit"}}>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
              <button className={"button"} onClick={() => onClickCapture(personRegistration)} disabled={!isName}>会員を登録する</button>
            </div>
          )
        }
      </div>
    </div>
  )
}
