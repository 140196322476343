import {createRoot} from "react-dom/client"
import {Auth0Provider} from "@auth0/auth0-react"
import {App} from "./App"

import {PersonRegistrationProvider} from "./components/providers/PersonRegistrationProvider"

const container = document.getElementById("root")
if (!container) throw new Error('Can not find root element.')
const root = createRoot(container)

root.render(
  <Auth0Provider domain={"dev-lmy08jov.us.auth0.com"} clientId={"V4iZT8mhfSlOzSBvTtwXzYkblg2mvnFx"} redirectUri={window.location.origin}>
    <PersonRegistrationProvider>
      <App/>
    </PersonRegistrationProvider>
  </Auth0Provider>
)
