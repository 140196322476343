import {SelectThresholdPropsType} from "../types/AppTypes"

export const SelectThreshold = (props: SelectThresholdPropsType) => {
  const {onThresholdChange, confidenceThreshold} = props
  const values = [10, 20, 30, 40, 50, 60, 70, 80, 90]

  return (
    <div>
      顔認証の自信度が
      <select onChange={onThresholdChange} className={"onThresholdChange"} value={confidenceThreshold}>
        {
          values.map((value, key) => <option value={value} key={key}>{value}%</option>)
        }
      </select>
      以上の場合に「一致した」と判断する<br/>
    </div>

  )
}
