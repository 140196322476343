import axios from "axios";
import {configuration} from "../configuration";
import {useCallback, useEffect, useState} from "react";

import {PersonType, PersonsListType} from "../types/AppTypes"

export const usePersonsList = () => {
  const [personsList, setPersonsList] = useState<PersonsListType>([])
  const [page, setPage] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLastPage, setIsLastPage] = useState<boolean>(false)

  const getImage = async (person_id: string) => {
    try {
      // 該当人物の登録済画像を取得する
      console.log("人物の画像を取得します")
      const response = await axios({
        method: 'get',
        url: `${configuration.baseUrl}/image/${person_id}`,
        headers: {'x-api-key': configuration.apiKey, 'Content-Type': 'image/jpeg'},
        responseType: 'arraybuffer'
      })
      console.log("人物の画像取得に成功しました。", response)
      return new Blob([response.data], {type: 'image/jpeg'})
      // setIdentificationImage(new Blob([response.data], {type: 'image/jpeg'}))
    } catch (error: any) {
      console.log("人物の画像取得に失敗しました。", error)
      return new Blob([], {})
    }
  }

  const getPersonsList = async () => {
    setIsLoading(true)
    // GET（登録済人物一覧)
    try {
      const response = await axios({
        method: 'get',
        url: `${configuration.baseUrl}/persons/${page}`,
        headers: {
          'x-api-key': configuration.apiKey,
          'Content-Type': 'multipart/form-data'
        }
      })
      // console.log(response)
      // const newPersonsList = response.data.map((person: any) => {
      const newPersonsList = await Promise.all(response.data.map(async (person: any) => {
        let image
        try {
          image = await getImage(person.person_id)
        } catch (error) {
          console.log(error)
          image = new Blob([], {})
        }

        return (
          {
            personId: person.person_id,
            name: person.user_data,
            image
          }
        )
      }))
      console.log("newPersonsList: ", newPersonsList)
      setPersonsList(newPersonsList)
      setIsLastPage(false)
    } catch (error: any) {
      if (error.response.status === 404 && error.response.data.code === 'not_found_page') {
        console.log("全ての情報を取得しました")
        setIsLastPage(true)
      } else {
        console.log("登録済人物一覧の取得時にエラーが発生しました。： ", error)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onClickNext = () => {
    console.log("onClickNext:page=", page)
    setPage(() => page + 1)
  }

// const onClickNext = useCallback(() => {
//   console.log("onClickNext:page=", page)
//   setPage(() => page + 1)
// }, [])

  const onClickBack = () => {
    console.log("onClickBack:page=", page)
    if (page <= 1) {
      setPage(1)
    } else {
      setPage(() => page - 1)
    }
  }

// const onClickBack = useCallback(() => {
//   console.log("onClickBack:page=", page)
//   if (page <= 1) {
//     setPage(1)
//   } else {
//     setPage(() => page - 1)
//   }
// }, [])

  const onClickDeleteButton = async (person: {person_id: string, index: number}) => {
    const {person_id, index} = person
    try {
      // 指定された人物の登録済画像を削除する
      console.log("人物の画像を削除します。: ", person_id)
      const response = await axios({
        method: 'delete',
        url: `${configuration.baseUrl}/persons/${person_id}`,
        headers: {'x-api-key': configuration.apiKey}
      })
      console.log("人物の画像削除に成功しました。：", response)
      const newPersonsList = [...personsList]
      newPersonsList.splice(index, 1)
      setPersonsList(newPersonsList)
    } catch (error: any) {
      console.log("人物の画像削除に失敗しました。", error)
    }
  }

  useEffect(() => {
    getPersonsList()
  }, [page])

  return (
    {
      getPersonsList,
      personsList,
      onClickBack,
      onClickNext,
      isLoading,
      page,
      isLastPage,
      onClickDeleteButton
    }
  )
}
