import {useAuth0} from "@auth0/auth0-react"
import "../App.css"

export const LogoutButton = () => {
  const {logout} = useAuth0()

  return (
    <button onClick={() => logout({returnTo: window.location.origin})} className={"logoutButton"}>
      ログアウト
    </button>
  )
}
