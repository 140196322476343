import {memo} from "react"
import {Header} from "../components/Header"
import {Title} from "../components/Title"
import {NavLink} from "react-router-dom"

export const TopPage = memo(() => {
  return (
    <div>
      <Header/>
      <Title/>
      <div className={"top"}>
        <h3>
          写真を使った検証
        </h3>
        <p> 〜「顔認証システム」の仕組みやクセを把握する〜</p>
        <ul>
          <li>
            <NavLink to={"members"}>会員登録</NavLink>
          </li>
          <li>
            <NavLink to={"pos"}>セルフレジ</NavLink>
          </li>
        </ul>
        <h3>
          Webcamを使った、より現実に近い検証
        </h3>
        <p>〜PoCに向けたイメージを膨らませる〜</p>
        <ul>
          <li>
            <NavLink to={"members-webcam"}>会員登録(Webcam)</NavLink>
          </li>
          <li>
            <NavLink to={"pos-webcam"}>セルフレジ（Webcam）</NavLink>
          </li>
        </ul>
        <h3>
          その他
        </h3>
        <ul>
          <li>
            <NavLink to={"maintenance"}>メンテナンス</NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
})
